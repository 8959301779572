import React from 'react';
import logo from '../assets/images/nerdetcetera.png';

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer-container">
                <div className="footer-left">©2023 | Centrais Elétricas Brasileiras S.A. - Eletrobras</div>
                <div className="footer-right">Desenvolvido por <a className="dev_link" target="_blank" href="https://nerdetcetera.com"><img src={logo} /></a></div>
            </div>
        </div>
    );
}