import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function Home() {

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [page, setPage] = useState('welcome');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [cad_name, setCadName] = useState('');
    const [cad_email, setCadEmail] = useState('');
    const [cad_domain, setCadDomain] = useState('@eletrobras.com');
    
    const [id, setId] = useState(localStorage.getItem('id_login'));
    const [name, setName] = useState(localStorage.getItem('name_login'));

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [success_message, setSuccessMessage] = useState('');

    async function sendLogin(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(email=="" || password==""){
                setLoading(false);
                setError(true);
                setErrorMessage("Preencha os dados do Acesso!");
                clearMessage();
                return;
            }
            const response = await api.post(`/eletrobras-login?email=${email}&password=${password}`);

            setLoading(false);
            if(response.data.status==='success'){
                setError(false);
                setId(response.data.id_user);
                setName(response.data.name);
                localStorage.setItem('id_login', response.data.id_user);
                localStorage.setItem('name_login', response.data.name);

                navigate('/live');

            }else if(response.data.status==='error'){
                setError(true);
                setErrorMessage("Acesso não encontrado, clique em Cadastrar!");
                clearMessage();
            }else{
                setError(false);
                setSuccessMessage("Acesso não encontrado, clique em Cadastrar!");
                clearMessage();
            }

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage("Erro ao fazer login!");
            clearMessage();
        }
    }

    async function sendRegister(e){
        e.preventDefault();
        setLoading(true);
        setError(false);

        const email_user = cad_email.split('@')[0];
        const cad_email_user = email_user + cad_domain;

        try{
            if(cad_name==""){
                setLoading(false);
                setError(true);
                setErrorMessage("Preencha seu Nome!");
                clearMessage();
                return;
            }
            if(cad_email==""){
                setLoading(false);
                setError(true);
                setErrorMessage("Preencha seu E-mail!");
                clearMessage();
                return;
            }

            const response = await api.post(`/eletrobras-register?name=${cad_name}&email=${cad_email_user}`);

            setLoading(false);
            if(response.data.status==='success'){
                setError(false);
                setPage('login');
                setSuccess(true);
                setSuccessMessage('Você receberá um e-mail com link e senha para assistir a transmissão ao vivo!');
            }else if(response.data.status==='exist'){
                setError(false);
                setPage('login');
                setSuccess(true);
                setSuccessMessage('Verifique seu e-mail com link e senha para assistir a transmissão ao vivo!');
            }else{
                setError(false);
                setSuccessMessage("Erro ao fazer cadastro!");
                clearMessage();
            }

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage("Erro ao fazer cadastro!");
            clearMessage();
        }
    }

    function changeCadEmail(value) {
        const updatedEmail = value.includes('@') ? value.split('@')[0] : value;
        setCadEmail(updatedEmail);
    }

    async function getUser() {
        const storageId = localStorage.getItem('id_login');
        const storageName = localStorage.getItem('name_login');

        if (storageId && storageName && storageName) {
            setId(storageId);
            setName(storageName);

            navigate('/live');
        }

    }

    async function logout() {
        localStorage.clear();

        setId(null);
        setName('');

        navigate('/');
    }

    function changePage(page, e){
        e.preventDefault();
        setPage(page);
    }

    function clearMessage(){
        setTimeout(() => {
            setError(false);
            setErrorMessage("");
            setSuccess(false);
            setSuccessMessage("");
        }, 2000);
    }

    useEffect(() => {
        getUser();
    }, []);

    return (
        <div className="page">
            <Header name={name} logout={logout} />
            <div className="content">
                {id==null &&
                <div className="full mt-big mb-big text-center">
                    {success && <div className="success space mb-30">{success_message}</div>}
                    {error && <div className="error space mb-30">{error_message}</div>}

                    {page=='welcome' &&
                    <form onSubmit={sendRegister} className={`form ${ loading ? 'loading' : '' }`}>
                        <h3 className="title_g mb-20">
                            Seja bem-vindo(a), coloque seu Nome e E-mail<br />
                            para prosseguir com seu cadastro:
                        </h3>
                        <div className="d-input">
                            <label>Nome:</label>
                            <input type="text" value={cad_name} onChange={e => setCadName(e.target.value)} />
                        </div>
                        <div className="d-input">
                            <label>E-mail:</label>
                            <div className="d-select">
                                <select value={cad_domain} onChange={e => setCadDomain(e.target.value)}>
                                    <option value="@eletrobras.com">@eletrobras.com</option>
                                    <option value="@furnas.com.br">@furnas.com.br</option>
                                </select>
                            </div>
                            <input type="text" value={cad_email} onChange={e => changeCadEmail(e.target.value)} />
                        </div>
                        <div className="d-btn mt-30">
                            <button type="submit" className="btn-send">ENVIAR</button>
                        </div>
                        <div className="d-btn mt-50">
                            <h3 className="title_b mb-20">Já tem cadastro?</h3>
                            <a href="#" onClick={(e) => changePage('login', e)} className="btn-send-invert">ENTRAR</a>
                        </div>
                    </form>}

                    {page=='login' &&
                    <form onSubmit={sendLogin} className={`form ${ loading ? 'loading' : '' }`}>
                        <h3 className="title_g mb-20">Faça seu Login para acessar a transmissão ao vivo:</h3>
                        <div className="d-input">
                            <label>E-mail:</label>
                            <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className="d-input">
                            <label>Senha:</label>
                            <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                        </div>
                        <div className="d-btn mt-30">
                            <button type="submit" className="btn-send">ENTRAR</button>
                        </div>
                        <div className="d-btn mt-50">
                            <h3 className="title_b mb-20">Ainda não tem cadastro?</h3>
                            <a href="#" onClick={(e) => changePage('welcome', e)} className="btn-send-invert">CADASTRAR</a>
                        </div>
                    </form>}

                </div>}
            </div>
            <Footer />
        </div>
    );
}
