import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Live from './pages/Live';

export default function Routing() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/live" element={<Live />} />
            </Routes>
        </BrowserRouter>
    );
}