import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import header from '../assets/images/header.jpg';
import { FiPower } from "react-icons/fi";

export default function Header(props) {

    const { name, logout } = props;

    return (
        <div className="header"  style={{backgroundImage: `url(${header})`}}>
            <div className="header-container">
                <Link to="/"><img src={logo} className="logo" /></Link>
                {(name!="" && name!=null) &&
                <div className="header-action">
                    <div className="header_text">Olá, <strong>{name}</strong>!</div>
                    <a className="header_link" onClick={logout}>
                        <FiPower size={18} />
                        <div>Sair</div>
                    </a>
                </div>}
            </div>
        </div>
    );
}