import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import video from '../../assets/images/video.jpg';

export default function Live() {

    const navigate = useNavigate();

    const refVideo = useRef();
    
    const [id, setId] = useState(localStorage.getItem('id_login'));
    const [name, setName] = useState(localStorage.getItem('name_login'));

    const id_video = "3842959";//https://vimeo.com/event/3842959/embed

    async function getUser() {
        const storageId = localStorage.getItem('id_login');
        const storageName = localStorage.getItem('name_login');

        if (storageId && storageName && storageName) {
            setId(storageId);
            setName(storageName);
        }

        if(storageId==null){
            navigate('/');
        }
    }

    async function logout() {
        localStorage.clear();

        setId(null);
        setName('');

        navigate('/');
    }

    useEffect(() => {
        getUser();
    }, []);

    return (
        <div className="page">
            <Header name={name} logout={logout} />
            <div className="content">
                {id!=null &&
                <div className="full-video">
                    <div ref={refVideo} className="embed-container">
                        {id_video==null ?
                        <img src={video} className="image" />:
                        <ReactPlayer
                            url={`https://vimeo.com/event/${id_video}`}
                            controls={true}
                            playsinline={true}
                            playing={true}
                            frameBorder="0"
                            allowFullScreen
                        />}
                    </div>
                </div>}
            </div>
            <Footer />
        </div>
    );
}
